<template>
  <section>
    <div class="panel" :class="{ 'focus': isSpendingEdit }" :style="[isSpendingEdit ? focusStyle : {}]">
      <p v-if="!hidePanelHeading" class="panel-heading columnhead">What is your estimated monthly retirement spending?  <strong class="spendingnum">${{formatSpending(spending)}}</strong></p>
      <div class="panel-block">
        <section id="slider-container">
          <vueSlider ref="vueslider" v-model='spendingInput' :min="slider.min" :max="slider.max" :interval="slider.interval"
              :lineHeight="slider.lineHeight" :formatter="slider.formatter" :tooltipStyle="slider.tooltipStyle" />
              <p style="text-align:center;">Drag Slider to Enter Monthly Spending</p>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueSlideBar from 'vue-slide-bar'
import vueSlider from 'vue-slider-component'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      spendingInput: 6000,
      slider: {
        min: 0,
        max: 30000,
        interval: 100,
        lineHeight: 20,
        tooltipStyle: {
          fontSize: '20px'
        },
        formatter: (v) => {
          return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(v)
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  watch: {
    spendingInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateSpending(newValue)
      }
    },
    spending: function () {
      if (this.spending > 0) {
        this.spendingInput = this.spending
      }
    }
  },
  components: {
    VueSlideBar,
    vueSlider
  },
  computed: {
    ...mapGetters([
      'spending',
      'getEditMode'
    ]),
    isSpendingEdit: function () {
      return (this.getEditMode === 'spending')
    }
  },
  methods: {
    ...mapActions({
      updateSpending: 'updateSpending'
    }),
    refreshSlider () {
      // call refresh on slider to initialize slider size
      // because the initial slider size is 0 when rendered in a hidden tab
      if (this.$refs.vueslider) {
        this.$nextTick(() => this.$refs.vueslider.refresh())
      }
    },
    formatSpending (spending) {
      let val = (spending / 1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  created () {
    if (this.spending > 0) {
      this.spendingInput = this.spending
    }
  },
  mounted () {
    if (this.spending > 0) {
      this.spendingInput = this.spending
    }
    if (this.isSpendingEdit) {
      this.$scrollTo('#spending-container', 300)
    }
  }
}
</script>

<style scoped>
  #slider-container {
    padding-top: 30px;
    width: 100%;
  }
  div.vue-slider{
    height:15px;
  }
</style>
