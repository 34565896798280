<template>
  <transition name="slide-left">
    <div>
      <component :is="useTemplate" :stepColor="wizardStepColor" :errorColor="wizardErrorColor" />
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import QuickstartForm from '@/components/QuickstartForm'
import QuickstartForm2 from '@/components/QuickstartForm2'
import QuickstartStepForm from '@/components/QuickstartStepForm'
import QuickstartMiniStepForm from '@/components/QuickstartMiniStepForm'
import QuickstartForm5 from '@/components/QuickstartForm5'
import templateMap from '@/templateMap'

export default {
  name: 'home',
  props: {
    templateKey: '',
    wizardStepColor: '',
    wizardErrorColor: ''
  },
  components: {
    QuickstartForm,
    QuickstartForm2,
    QuickstartStepForm,
    QuickstartForm5,
    QuickstartMiniStepForm
  },
  data () {
    return {
      templateMap: templateMap
    }
  },
  computed: {
    useTemplate () {
      var templateName = templateMap[this.templateKey]
      return (templateName !== 'undefined') ? templateName : 'QuickstartForm'
    }
  },
  mounted () {
    this.$store.dispatch('setCurrentPage', this.$router.currentRoute.meta.page)
  }
}
</script>
