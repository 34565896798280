<template>
  <div class="columns">
    <div class="column">
      <form ref="form" @submit.prevent="onSubmit">
        <form-wizard @on-complete="onSubmit" title="" subtitle="" shape="circle" @on-change="refreshSlider" transition="fade">
          <tab-content v-if="getUseEmailStarter" title="Get Started" icon="fas fa-user" :before-change="saveContact">
              <email-starter hide-panel-heading></email-starter>
            </tab-content>
          <tab-content title="Household Profile" icon="fas fa-home">
            <demographics hide-panel-heading></demographics>
          </tab-content>
          <tab-content title="Monthly Spending" icon="fas fa-money-bill-wave" >
            <spending hide-panel-heading ref="spending" />
          </tab-content>
          <tab-content title="Retirement Savings" icon="fas fa-signal">
            <savings v-show="getPlanType === null" hide-panel-heading></savings>
            <savings-plan v-show="getPlanType !== null" hide-panel-heading></savings-plan>
          </tab-content>
          <tab-content title="Retirement Income" icon="fas fa-money-check-alt">
            <income hide-panel-heading ></income>
          </tab-content>
        </form-wizard>
      </form>
    </div>
    <div class="column">
      <div id="promo-container" class="column">
        <div v-html="getContentSnippet('promo-content')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters } from 'vuex'
import EmailStarter from '@/components/EmailStarter'
import Demographics from '@/components/Demographics'
import Savings from '@/components/Savings'
import SavingsPlan from '@/components/SavingsPlan'
import Income from '@/components/Income'
import Spending from '@/components/SpendingSlider'
import { MonthlyApiService } from '@/services/MonthlyApiService'

const monthlyApiService = new MonthlyApiService()

export default {
  components: {
    EmailStarter,
    FormWizard,
    TabContent,
    Demographics,
    Income,
    Savings,
    SavingsPlan,
    Spending
  },
  computed: {
    ...mapGetters({
      stepper: 'stepper',
      getContentSnippet: 'getContentSnippet',
      getPlanType: 'getPlanType',
      getUseEmailStarter: 'getUseEmailStarter'
    })
  },
  methods: {
    saveContact () {
      return new Promise((resolve, reject) => {
        this.$validator.validate('step0.*').then(valid => {
          if (valid) {
            if (this.$store.getters.requestHash === 'new') {
              monthlyApiService.startQuickstart().then((response) => {
                this.$store.dispatch('updateRequestHash', response.data.payload)
              }
              )
            }
          }
          resolve(valid)
        }
        )
        this.stepComplete('Get Started')
      }
      )
    },
    onSubmit () {
      monthlyApiService.startQuickstart().then((response) => {
        this.$store.dispatch('updateRequestHash', response.data.payload)
        monthlyApiService.startOptimize().then((response) => {
          this.$store.dispatch('updateQuickstartResults', response.data.payload)
          this.$router.push({ path: 'results' })
        }
        )
      }
      )
    },
    refreshSlider: function () {
      if (this.$refs.spending) {
        this.$refs.spending.refreshSlider()
      }
    },
    tabChange: function () {
      let activeTab = this.$refs.wizard.tabs[this.$refs.wizard.activeTabIndex].title
      this.$gtm.trackEvent({
        event: 'quickstart_step_event',
        category: 'Quickstart Steps',
        action: 'start',
        label: activeTab
      })
      if (this.$refs.spending) {
        this.$refs.spending.refreshSlider()
      }
    },
    stepComplete: function (step) {
      this.$gtm.trackEvent({
        event: 'quickstart_step_event',
        category: 'Quickstart Steps',
        action: 'complete',
        label: step
      })
      return true
    }
  }
}
</script>

<style scoped>
</style>
