<template>
  <div id="sensitivity-impacts-container">
    <div class="columns">
      <div class="column">
        <h2 class="sectionheader">How sensitive is your retirement plan?</h2>
        <div id="sensitivity-impacts-content" v-html="getContentSnippet('impacts-content')">
        </div>
        <sensitivity-table></sensitivity-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SensitivityTable from '@/components/SensitivityTable'

export default {
  data () {
    return {

    }
  },
  components: {
    SensitivityTable
  },
  computed: {
    ...mapGetters([
      'getContentSnippet'
    ])
  }
}
</script>

<style scoped>
  #sensitivity-impacts-content {
  padding-bottom: 15px;
  }
</style>
