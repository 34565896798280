<template>
  <div id="get-more-zone-container">
    <div class="columns">
    <div class="column">
      <h2 class="sectionheader">Your Get More Amount</h2>
      <div class="gmzcontent" v-html="getContentSnippet('get-more-zone-content')">
      </div>
      <div v-html="getContentSnippet('longevity-graph-content')">
      </div>
    </div>
    <div class="column">
      <div v-if="getMoreAmount > -1">
        <div id="get-more-chart-container">
          <get-more-bar-chart :chart-data="getMoreChartData" :options="getMoreChartOptions" :width="300" :height="300"></get-more-bar-chart>
        </div>
      </div>
    </div>
    </div>
    <div class="columns">
      <div class="thickdivider"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GetMoreBarChart from '@/components/charts/GetMoreBarChart.js'
import ChartJsPluginWaterFall from 'chartjs-plugin-waterfall'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

class BarChartItem {
  constructor (label, data, backgroundColor, stack) {
    this.label = label
    this.data = data
    this.backgroundColor = backgroundColor
    this.stack = stack
  }
}

class DummyChartItem {
  constructor (label, data, stack) {
    this.label = label
    this.data = data
    this.stack = stack
    this.waterfall = {
      dummyStack: true
    }
  }
}

export default {
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltipenabled: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 8,
            fontSize: 14,
            filter: function (legendItem, chartData) {
              var currentDataset = chartData.datasets[legendItem.datasetIndex]
              return (currentDataset.waterfall) ? !currentDataset.waterfall.dummyStack : true
            }
          }
        },
        pointLabels: {
          display: true
        },
        scales: {
          yAxes: [{
            id: 'default',
            ticks: {
              callback: function (label, index, labels) {
                if (label) {
                  return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(label)
                }
              }
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 0,
            font: {
              size: 14
            },
            display: function (context) {
              var currentDataset = context.dataset
              return (currentDataset.waterfall) ? !currentDataset.waterfall.dummyStack : true
            },
            formatter: function (value, context) {
              if (value) {
                return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value)
              }
            }
          }
        }
      }
    }
  },
  components: {
    GetMoreBarChart,
    ChartJsPluginWaterFall,
    ChartJsPluginDataLabels
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getMoreAmount',
      'getSubOptimalAmount',
      'getOptimalAmount'
    ]),
    getMoreChartData: function () {
      let chartData = {}
      let datasets = []
      datasets.push(new BarChartItem('Typical Strategy', [this.getSubOptimalAmount], '#343438', 'stack 1'))
      datasets.push(new DummyChartItem('', [this.getSubOptimalAmount], 'stack 2'))
      datasets.push(new BarChartItem('Get More Amount', [this.getMoreAmount], '#09d875', 'stack 2'))
      datasets.push(new BarChartItem('Smart Strategy', [this.getOptimalAmount], '#2a97f7', 'stack 3'))
      chartData.datasets = datasets
      return chartData
    },
    getMoreChartOptions: function () {
      let opts = this.options
      // bump up the max from the tallest bar to provide room so amounts can be displayed above the bars
      opts.scales.yAxes[0].ticks.suggestedMax = this.getOptimalAmount * 1.2
      return opts
    }
  },
  methods: {
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style scoped>
  #get-more-chart-container {
    background-color: #ffffff;
    border-radius:25px;
    padding: 15px 10px;
  }
  .gmzcontent{
    margin-top:25px;
  }
</style>
