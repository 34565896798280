import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
import Cosmic from './services/cosmic'

Vue.use(Vuex)
Vue.use(VueCookies)

const store = new Vuex.Store({
  strict: true,
  state: {
    initialView: true,
    hideBanner: false,
    useGetMoreTeaser: true,
    currentPage: 0,
    apiKey: 'abc-123',
    requestHash: 'new',
    step: 0,
    dirtyForm: false,
    editMode: null,
    emailReportModalActive: false,
    getMoreTeaserModalActive: false,
    contentSnippets: [],
    contentImages: [],
    allocationStrategies: [],
    quickstartConfiguration: {},
    quickstartRequest: {},
    quickstartResults: {},
    sensitivityResults: {},
    stepper: {
      step: undefined,
      steps: 3
    }
  },
  mutations: {
    INITIALIZE_API_KEY: (state, payload) => {
      state.apiKey = payload
    },
    UPDATE_HIDE_BANNER: (state, payload) => {
      state.hideBanner = payload
    },
    UPDATE_REQUEST_HASH: (state, payload) => {
      state.requestHash = payload
      window.$cookies.set('requestHash', payload)
    },
    UPDATE_QUICKSTART_REQUEST: (state, payload) => {
      state.quickstartRequest = payload
    },
    UPDATE_DIRTY_FORM: (state, payload) => {
      state.dirtyForm = payload
    },
    UPDATE_QUICKSTART_REQUEST_FROM_RESPONSE: (state, payload) => {
      state.quickstartRequest.emailVerified = payload.emailVerified
      state.quickstartRequest.emailVerificationScore =
        payload.emailVerificationScore
      state.quickstartRequest.bucketId = payload.bucketId
      state.quickstartRequest.bucketCreditId = payload.bucketCreditId
      state.quickstartRequest.leadState = payload.leadState
    },
    UPDATE_ALLOCATION_STRATEGIES: (state, payload) => {
      state.allocationStrategies = payload
    },
    UPDATE_QUICKSTART_CONFIGURATION: (state, payload) => {
      state.quickstartConfiguration = payload
    },
    SET_EDIT_MODE: (state, payload) => {
      state.editMode = payload
    },
    CLEAR_EDIT_MODE: (state) => {
      state.editMode = null
    },
    UPDATE_IS_MARRIED: (state, payload) => {
      state.quickstartRequest.married = payload === 'true'
      state.quickstartRequest.isMarried = payload === 'true' ? 1 : 0
    },
    UPDATE_CLIENT_EMAIL: (state, payload) => {
      state.quickstartRequest.clientEmail = payload
    },
    UPDATE_CLIENT_PHONE: (state, payload) => {
      state.quickstartRequest.clientPhoneNumber = payload
    },
    UPDATE_CLIENT_NAME: (state, payload) => {
      state.quickstartRequest.retireeName = payload
    },
    UPDATE_CLIENT_NAME_PREFIX: (state, payload) => {
      state.quickstartRequest.retireeNamePrefix = payload
    },
    UPDATE_CLIENT_AGE: (state, payload) => {
      state.quickstartRequest.retireeAge = payload
    },
    UPDATE_CLIENT_RETIREMENT_AGE: (state, payload) => {
      state.quickstartRequest.retireeRetirementAge = payload
    },
    UPDATE_RETIREE_LIFE_EXPECTANCY: (state, payload) => {
      state.quickstartRequest.retireeLifeExpectancy = payload
    },
    UPDATE_PARTNER_NAME: (state, payload) => {
      state.quickstartRequest.spouseName = payload
    },
    UPDATE_PARTNER_NAME_PREFIX: (state, payload) => {
      state.quickstartRequest.spouseNamePrefix = payload
    },
    UPDATE_PARTNER_AGE: (state, payload) => {
      state.quickstartRequest.spouseAge = payload
    },
    UPDATE_PARTNER_RETIREMENT_AGE: (state, payload) => {
      state.quickstartRequest.spouseRetirementAge = payload
    },
    UPDATE_PARTNER_LIFE_EXPECTANCY: (state, payload) => {
      state.quickstartRequest.spouseLifeExpectancy = payload
    },
    UPDATE_CLIENT_SS_BENEFIT_AMOUNT: (state, payload) => {
      state.quickstartRequest.retireeSocialSecurityMonthlyPia = payload
    },
    UPDATE_CLIENT_OTHER_MONTHLY_INCOME: (state, payload) => {
      state.quickstartRequest.retireeOtherMonthlyIncome = payload
    },
    UPDATE_CLIENT_MONTHLY_PENSION: (state, payload) => {
      state.quickstartRequest.retireePensionMonthlyIncome = payload
    },
    UPDATE_PARTNER_SS_BENEFIT_AMOUNT: (state, payload) => {
      state.quickstartRequest.spouseSocialSecurityMonthlyPia = payload
    },
    UPDATE_PARTNER_OTHER_MONTHLY_INCOME: (state, payload) => {
      state.quickstartRequest.spouseOtherMonthlyIncome = payload
    },
    UPDATE_PARTNER_MONTHLY_PENSION: (state, payload) => {
      state.quickstartRequest.spousePensionMonthlyIncome = payload
    },
    UPDATE_INVESTMENT_PROFILE: (state, payload) => {
      state.quickstartRequest.allocationId = payload
    },
    UPDATE_TAXABLE_BALANCE: (state, payload) => {
      state.quickstartRequest.taxableBalance = payload
    },
    UPDATE_PLAN_TAXABLE_BALANCE: (state, payload) => {
      state.quickstartRequest.planTaxableBalance = payload
    },
    UPDATE_TAXDEFERRED_BALANCE: (state, payload) => {
      state.quickstartRequest.taxDeferredBalance = payload
    },
    UPDATE_PLAN_TAXDEFERRED_BALANCE: (state, payload) => {
      state.quickstartRequest.planTaxDeferredBalance = payload
    },
    UPDATE_TAXDEFERRED_CONTRIBUTION: (state, payload) => {
      state.quickstartRequest.taxDeferredContribution = payload
    },
    UPDATE_TAXEXEMPT_BALANCE: (state, payload) => {
      state.quickstartRequest.taxExemptBalance = payload
    },
    UPDATE_PLAN_TAXEXEMPT_BALANCE: (state, payload) => {
      state.quickstartRequest.planTaxExemptBalance = payload
    },
    UPDATE_ANNUITY_BALANCE: (state, payload) => {
      state.quickstartRequest.annuityBalance = payload
    },
    UPDATE_INHERITEDIRA_BALANCE: (state, payload) => {
      state.quickstartRequest.taxInheritedIraBalance = payload
    },
    UPDATE_SPENDING: (state, payload) => {
      state.quickstartRequest.householdMonthlyRetirementSpending = payload
    },
    UPDATE_QUICKSTART_RESULTS: (state, payload) => {
      state.quickstartResults = payload
      state.dirtyForm = false
    },
    UPDATE_SENSITIVITY_RESULTS: (state, payload) => {
      state.sensitivityResults = payload
    },
    SET_CONTENT_SNIPPETS: (state, payload) => {
      state.contentSnippets = payload
    },
    APPEND_CONTENT_SNIPPETS: (state, payload) => {
      Object.keys(payload).forEach(function (key, index) {
        state.contentSnippets[key] = payload[key]
      })
    },
    SET_CONTENT_IMAGES: (state, payload) => {
      state.contentImages = payload
    },
    SET_CURRENT_PAGE: (state, payload) => {
      state.currentPage = payload
    },
    SET_STEP: (state, payload) => {
      state.step = payload
    },
    SET_LEAD_SOURCE: (state, payload) => {
      if (state.quickstartRequest.requestHash === 'new') {
        state.quickstartRequest.leadSource = payload
      }
    },
    TOGGLE_EMAIL_REPORT_MODAL_ACTIVE: state => {
      state.emailReportModalActive = !state.emailReportModalActive
    },
    SET_EMAIL_REPORT_MODAL_ACTIVE: (state, payload) => {
      state.emailReportModalActive = payload
    },
    TOGGLE_GETMORE_TEASER_MODAL_ACTIVE: state => {
      state.getMoreTeaserModalActive = !state.getMoreTeaserModalActive
    },
    SET_GETMORE_TEASER_MODAL_ACTIVE: (state, payload) => {
      state.getMoreTeaserModalActive = payload
    },
    TOGGLE_INITIAL_VIEW: (state) => {
      state.initialView = !state.initialView
    }
  },
  actions: {
    initializeApiKey ({ commit }, payload) {
      if (payload) {
        commit('INITIALIZE_API_KEY', payload)
      }
    },
    clearDirtyForm ({ commit }) {
      commit('UPDATE_DIRTY_FORM', false)
    },
    clearEditMode ({ commit }) {
      commit('CLEAR_EDIT_MODE')
    },
    updateDirtyForm ({ commit }, payload) {
      commit('UPDATE_DIRTY_FORM', payload)
    },
    updateHideBanner ({ commit }, payload) {
      commit('UPDATE_HIDE_BANNER', payload)
    },
    updateRequestHash ({ commit }, payload) {
      if (payload) {
        commit('UPDATE_REQUEST_HASH', payload)
      }
    },
    updateQuickstartRequest ({ commit }, payload) {
      commit('UPDATE_QUICKSTART_REQUEST', payload)
    },
    updateQuickstartRequestFromResponse ({ commit }, payload) {
      commit('UPDATE_QUICKSTART_REQUEST_FROM_RESPONSE', payload)
    },
    updateAllocationStrategies ({ commit }, payload) {
      commit('UPDATE_ALLOCATION_STRATEGIES', payload)
    },
    updateQuickstartConfiguration ({ commit }, payload) {
      commit('UPDATE_QUICKSTART_CONFIGURATION', payload)
    },
    toggleInitialView ({ commit }) {
      commit('TOGGLE_INITIAL_VIEW')
    },
    setEditMode ({ commit }, payload) {
      commit('SET_EDIT_MODE', payload)
    },
    updateIsMarried ({ commit }, payload) {
      commit('UPDATE_IS_MARRIED', payload)
    },
    updateClientEmail ({ commit }, payload) {
      commit('UPDATE_CLIENT_EMAIL', payload)
    },
    updateClientPhoneNumber ({ commit }, payload) {
      commit('UPDATE_CLIENT_PHONE', payload)
    },
    updateClientName ({ commit }, payload) {
      commit('UPDATE_CLIENT_NAME', payload)
    },
    updateClientNamePrefix ({ commit }, payload) {
      commit('UPDATE_CLIENT_NAMEPREFIX', payload)
    },
    updateClientAge ({ commit }, payload) {
      commit('UPDATE_CLIENT_AGE', payload)
    },
    updateClientRetirementAge ({ commit }, payload) {
      commit('UPDATE_CLIENT_RETIREMENT_AGE', payload)
    },
    updateClientLifeExpectancy ({ commit }, payload) {
      commit('UPDATE_CLIENT_LIFE_EXPECTANCY', payload)
    },
    updateClientSocialSecurityBenefitAmount ({ commit }, payload) {
      commit('UPDATE_CLIENT_SS_BENEFIT_AMOUNT', payload)
    },
    updateClientMonthlyPension ({ commit }, payload) {
      commit('UPDATE_CLIENT_MONTHLY_PENSION', payload)
    },
    updateClientOtherMonthlyIncome ({ commit }, payload) {
      commit('UPDATE_CLIENT_OTHER_MONTHLY_INCOME', payload)
    },
    updatePartnerName ({ commit }, payload) {
      commit('UPDATE_PARTNER_NAME', payload)
    },
    updatePartnerNamePrefix ({ commit }, payload) {
      commit('UPDATE_PARTNER_NAME_PREFIX', payload)
    },
    updatePartnerAge ({ commit }, payload) {
      commit('UPDATE_PARTNER_AGE', payload)
    },
    updatePartnerRetirementAge ({ commit }, payload) {
      commit('UPDATE_PARTNER_RETIREMENT_AGE', payload)
    },
    updatePartnerLifeExpectancy ({ commit }, payload) {
      commit('UPDATE_PARTNER_LIFE_EXPECTANCY', payload)
    },
    updatePartnerSocialSecurityBenefitAmount ({ commit }, payload) {
      commit('UPDATE_PARTNER_SS_BENEFIT_AMOUNT', payload)
    },
    updatePartnerMonthlyPension ({ commit }, payload) {
      commit('UPDATE_PARTNER_MONTHLY_PENSION', payload)
    },
    updatePartnerOtherMonthlyIncome ({ commit }, payload) {
      commit('UPDATE_PARTNER_OTHER_MONTHLY_INCOME', payload)
    },
    updateInvestmentProfile ({ commit }, payload) {
      commit('UPDATE_INVESTMENT_PROFILE', payload)
    },
    updateTaxableBalance ({ commit }, payload) {
      commit('UPDATE_TAXABLE_BALANCE', payload)
    },
    updatePlanTaxableBalance ({ commit }, payload) {
      commit('UPDATE_PLAN_TAXABLE_BALANCE', payload)
    },
    updateTaxDeferredBalance ({ commit }, payload) {
      commit('UPDATE_TAXDEFERRED_BALANCE', payload)
    },
    updatePlanTaxDeferredBalance ({ commit }, payload) {
      commit('UPDATE_PLAN_TAXDEFERRED_BALANCE', payload)
    },
    updateTaxDeferredContribution ({ commit }, payload) {
      commit('UPDATE_TAXDEFERRED_CONTRIBUTION', payload)
    },
    updateTaxExemptBalance ({ commit }, payload) {
      commit('UPDATE_TAXEXEMPT_BALANCE', payload)
    },
    updatePlanTaxExemptBalance ({ commit }, payload) {
      commit('UPDATE_PLAN_TAXEXEMPT_BALANCE', payload)
    },
    updateAnnuityBalance ({ commit }, payload) {
      commit('UPDATE_ANNUITY_BALANCE', payload)
    },
    updateInheritedIraBalance ({ commit }, payload) {
      commit('UPDATE_INHERITEDIRA_BALANCE', payload)
    },
    updateSpending ({ commit }, payload) {
      commit('UPDATE_SPENDING', payload)
    },
    updateQuickstartResults ({ commit }, payload) {
      commit('UPDATE_QUICKSTART_RESULTS', payload)
    },
    updateSensitivityResults ({ commit }, payload) {
      commit('UPDATE_SENSITIVITY_RESULTS', payload)
    },
    setLeadSource ({ commit }, payload) {
      commit('SET_LEAD_SOURCE', payload)
    },
    setCurrentPage ({ commit }, payload) {
      commit('SET_CURRENT_PAGE', payload)
    },
    setStep ({ commit }, payload) {
      commit('SET_STEP', payload)
    },
    moveStep ({ commit }, payload) {
      if (this.state.step < payload) {
        commit('SET_STEP', payload)
      }
    },
    toggleEmailReportModalActive ({ commit }) {
      commit('TOGGLE_EMAIL_REPORT_MODAL_ACTIVE')
    },
    setEmailReportModalActive ({ commit }, payload) {
      commit('SET_EMAIL_REPORT_MODAL_ACTIVE', payload)
    },
    toggleGetMoreTeaserModalActive ({ commit }) {
      commit('TOGGLE_GETMORE_TEASER_MODAL_ACTIVE')
    },
    setGetMoreTeaserModalActive ({ commit }, payload) {
      commit('SET_GETMORE_TEASER_MODAL_ACTIVE', payload)
    },
    async fetchContentSnippets ({ commit }) {
      const params = {
        type_slug: 'snippets'
      }
      await Cosmic.getObjectsByType(params)
        .then(data => {
          console.log(data)
          let contentSnippets = {}
          data.objects.forEach(snippet => {
            const key = snippet.slug.toLowerCase()
            contentSnippets[key] = {}
            contentSnippets[key].title = snippet.title
            contentSnippets[key].content = snippet.content
          })
          // get custom Brand Tag text stripping embedded HTML
          let brandTag =
            this.state.quickstartConfiguration.brandName == null
              ? 'Get More &trade;'
              : this.state.quickstartConfiguration.brandName
          // loop through all content snippets replacing {{brand-tag}} with branded content
          Object.keys(contentSnippets).forEach(key => {
            let brandedContent = contentSnippets[key].content.replace(
              /{{brand-tag}}/gi,
              brandTag
            )
            contentSnippets[key].content = brandedContent
          })
          commit('SET_CONTENT_SNIPPETS', contentSnippets)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fetchSubDomainContentSnippets ({ commit }, subdomain) {
      let params = {
        type_slug: subdomain,
        hide_metafields: false
      }
      await Cosmic.getObjectsByType(params)
        .then(data => {
          console.log(data)
          let contentSnippets = {}
          if (data.status !== 'empty') {
            data.objects.forEach(snippet => {
              const key = snippet.metadata.parent_slug.toLowerCase()
              contentSnippets[key] = {}
              contentSnippets[key].title = snippet.title
              contentSnippets[key].content = snippet.content
            })
          }
          // get custom Brand Tag text stripping embedded HTML
          let brandTag =
            this.state.quickstartConfiguration.brandName == null
              ? 'Get More &trade;'
              : this.state.quickstartConfiguration.brandName
          // loop through all content snippets replacing {{brand-tag}} with branded content
          Object.keys(contentSnippets).forEach(key => {
            let brandedContent = contentSnippets[key].content.replace(
              /{{brand-tag}}/gi,
              brandTag
            )
            contentSnippets[key].content = brandedContent
          })
          commit('APPEND_CONTENT_SNIPPETS', contentSnippets)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fetchContentImages ({ commit }) {
      const params = {
        folder: 'quickstart'
      }
      await Cosmic.getMedia(params)
        .then(data => {
          let contentImages = {}
          data.media.forEach(image => {
            const key = image.original_name.toLowerCase()
            contentImages[key] = {}
            contentImages[key].url = image.imgix_url
          })
          commit('SET_CONTENT_IMAGES', contentImages)
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  getters: {
    stepper: state => {
      return state.stepper
    },
    isHideBanner: state => {
      return state.hideBanner
    },
    getUseGetMoreTeaser: state => {
      if (state.quickstartConfiguration.useEmailTeaser) {
        // if teaser flag is true and client fields are empty use teaser form
        if (!state.quickstartRequest.clientEmail || !state.quickstartRequest.clientPhoneNumber || !state.quickstartRequest.retireeName) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getUseEmailStarter: state => {
      return state.quickstartConfiguration.useEmailStarter
    },
    getAgeMin: state => {
      return (state.quickstartConfiguration.ageMin > 0) ? state.quickstartConfiguration.ageMin : 45
    },
    getAgeMax: state => {
      return (state.quickstartConfiguration.ageMax > 0) ? state.quickstartConfiguration.ageMax : 100
    },
    getRetirementAgeMin: state => {
      return (state.quickstartConfiguration.retirementAgeMin > 0) ? state.quickstartConfiguration.retirementAgeMin : 62
    },
    getRetirementAgeMax: state => {
      return (state.quickstartConfiguration.retirementAgeMax > 0) ? state.quickstartConfiguration.retirementAgeMax : 72
    },
    quickstartRequest: state => {
      return state.quickstartRequest
    },
    getDirtyForm: state => {
      // return state.dirtyForm
      return true
    },
    getEditMode: state => {
      return state.editMode
    },
    allocationStrategies: state => {
      return state.allocationStrategies
    },
    quickstartResults: state => {
      return state.quickstartResults
    },
    sensitivityResults: state => {
      if (
        typeof state.sensitivityResults !== 'undefined' &&
        Object.keys(state.sensitivityResults).length > 0
      ) {
        return state.sensitivityResults
      } else {
        return null
      }
    },
    isMarried: state => {
      return state.quickstartRequest.isMarried
    },
    married: state => {
      return state.quickstartRequest.married
    },
    clientEmail: state => {
      return state.quickstartRequest.clientEmail
    },
    clientPhoneNumber: state => {
      return state.quickstartRequest.clientPhoneNumber
    },
    clientName: state => {
      return state.quickstartRequest.retireeName
    },
    clientNamePrefix: state => {
      return state.quickstartRequest.retireeNamePrefix
    },
    clientAge: state => {
      return state.quickstartRequest.retireeAge
    },
    clientRetirementAge: state => {
      return state.quickstartRequest.retireeRetirementAge
    },
    clientLifeExpectancy: state => {
      return state.quickstartRequest.retireeLifeExpectancy
    },
    clientSocialSecurityBenefitAmount: state => {
      return state.quickstartRequest.retireeSocialSecurityMonthlyPia
    },
    clientMonthlyPension: state => {
      return state.quickstartRequest.retireePensionMonthlyIncome
    },
    clientOtherMonthlyIncome: state => {
      return state.quickstartRequest.retireeOtherMonthlyIncome
    },
    partnerName: state => {
      return state.quickstartRequest.spouseName
    },
    partnerNamePrefix: state => {
      return state.quickstartRequest.spouseNamePrefix
    },
    partnerAge: state => {
      return state.quickstartRequest.spouseAge
    },
    partnerRetirementAge: state => {
      return state.quickstartRequest.spouseRetirementAge
    },
    partnerLifeExpectancy: state => {
      return state.quickstartRequest.spouseLifeExpectancy
    },
    partnerSocialSecurityBenefitAmount: state => {
      return state.quickstartRequest.spouseSocialSecurityMonthlyPia
    },
    partnerMonthlyPension: state => {
      return state.quickstartRequest.spousePensionMonthlyIncome
    },
    partnerOtherMonthlyIncome: state => {
      return state.quickstartRequest.spouseOtherMonthlyIncome
    },
    investmentProfile: state => {
      if (state.quickstartRequest.investmentProfile !== 'undefined') {
        return state.quickstartRequest.allocationId
      } else {
        return state.allocationStrategies[0].strategyId
      }
    },
    taxableBalance: state => {
      return state.quickstartRequest.taxableBalance
    },
    planTaxableBalance: state => {
      return state.quickstartRequest.planTaxableBalance
    },
    taxDeferredBalance: state => {
      return state.quickstartRequest.taxDeferredBalance
    },
    planTaxDeferredBalance: state => {
      return state.quickstartRequest.planTaxDeferredBalance
    },
    taxDeferredContribution: state => {
      return state.quickstartRequest.taxDeferredContribution
    },
    taxExemptBalance: state => {
      return state.quickstartRequest.taxExemptBalance
    },
    planTaxExemptBalance: state => {
      return state.quickstartRequest.planTaxExemptBalance
    },
    annuityBalance: state => {
      return state.quickstartRequest.annuityBalance
    },
    inheritedIraBalance: state => {
      return state.quickstartRequest.inheritedIraBalance
    },
    spending: state => {
      if (state.quickstartRequest !== 'undefined') {
        return state.quickstartRequest.householdMonthlyRetirementSpending
      } else {
        return 0
      }
    },
    getDefaultSpendingRange: state => {
      return state.quickstartRequest.spendingRange
    },
    getHouseholdTotalAssets: (state, getters) => {
      let householdTotalAssets = getters.taxableBalance + getters.planTaxableBalance + getters.taxDeferredBalance + getters.planTaxDeferredBalance + getters.taxExemptBalance + getters.planTaxExemptBalance
      return householdTotalAssets
    },
    getNonPlanTotalAssets: (state, getters) => {
      let nonPlanTotalAssets = getters.taxableBalance + getters.taxDeferredBalance + getters.taxExemptBalance
      return nonPlanTotalAssets
    },
    getPlanTotalAssets: (state, getters) => {
      let planTotalAssets = getters.planTaxableBalance + getters.planTaxDeferredBalance + getters.planTaxExemptBalance
      return planTotalAssets
    },
    getCurrentInvestmentProfileAllocationMap: (state, getters) => {
      let currentInvestmentProfile = getters.investmentProfile
      for (let i = 0; i < state.allocationStrategies.length; i++) {
        if (state.allocationStrategies[i].strategyId === currentInvestmentProfile) {
          return state.allocationStrategies[i].categoryAllocationMap
        }
      }
    },
    getRiskTolerance: (state, getters) => {
      let currentInvestmentProfile = getters.investmentProfile
      for (let i = 0; i < state.allocationStrategies.length; i++) {
        if (state.allocationStrategies[i].strategyId === currentInvestmentProfile) {
          return state.allocationStrategies[i].riskTolerance
        }
      }
    },
    apiKey: state => {
      return state.apiKey
    },
    subdomain: state => {
      return state.quickstartConfiguration.subdomain
    },
    sampleReportHash: state => {
      return state.quickstartConfiguration.sampleReportHash
    },
    requestHash: state => {
      return state.requestHash
    },
    requestId: state => {
      return state.quickstartRequest.requestId
    },
    step: state => {
      return state.step
    },
    getBucketId: state => {
      return state.quickstartConfiguration.bucketId
    },
    getUseCustomThankYouPage: state => {
      return state.quickstartConfiguration.useCustomThankYouPage
    },
    getCustomThankYouUrl: state => {
      return state.quickstartConfiguration.customThankYouUrl
    },
    getLeadState: state => {
      return state.quickstartRequest.leadState
    },
    getLeadSource: state => {
      return state.quickstartRequest.leadSource
    },
    getMoreAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0 &&
        typeof state.quickstartResults.worstResponse !== 'undefined' &&
        state.quickstartResults.worstResponse !== null &&
        Object.keys(state.quickstartResults.worstResponse).length > 0
      ) {
        return (
          Math.floor(state.quickstartResults.bestResponse.total / 100) -
          Math.floor(state.quickstartResults.worstResponse.total / 100)
        )
      } else {
        return -1
      }
    },
    getSubOptimalAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.worstResponse !== 'undefined' &&
        state.quickstartResults.worstResponse !== null &&
        Object.keys(state.quickstartResults.worstResponse).length > 0
      ) {
        return Math.floor(state.quickstartResults.worstResponse.total / 100)
      } else {
        return null
      }
    },
    getSubOptimalResults: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.worstResponse !== 'undefined' &&
        state.quickstartResults.worstResponse !== null &&
        Object.keys(state.quickstartResults.worstResponse).length > 0
      ) {
        return state.quickstartResults.worstResponse.results.annualResultsList
      } else {
        return null
      }
    },
    getSubOptimalSSIAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.worstResponse !== 'undefined' &&
        state.quickstartResults.worstResponse !== null &&
        Object.keys(state.quickstartResults.worstResponse).length > 0
      ) {
        return Math.floor(
          state.quickstartResults.worstResponse.results.totalSocialSecurity / 100
        )
      } else {
        return null
      }
    },
    getOptimalAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0
      ) {
        return Math.floor(state.quickstartResults.bestResponse.total / 100)
      } else {
        return null
      }
    },
    getOptimalResults: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0
      ) {
        return state.quickstartResults.bestResponse.results.annualResultsList
      } else {
        return null
      }
    },
    getOptimalSSIAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0
      ) {
        return Math.floor(
          state.quickstartResults.bestResponse.results.totalSocialSecurity / 100
        )
      } else {
        return null
      }
    },
    getTotalValueIndicatorAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0
      ) {
        let totalValueDifference =
          state.quickstartResults.bestResponse.results.endingAfterTaxAssets -
          state.quickstartResults.worstResponse.results.endingAfterTaxAssets
        return Math.floor(totalValueDifference / 100)
      } else {
        return 0
      }
    },
    getEndingValueIndicatorAmount: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0
      ) {
        let endingValueDifference =
          state.quickstartResults.bestResponse.results.npvEndingAfterTaxAssets -
          state.quickstartResults.worstResponse.results.npvEndingAfterTaxAssets
        return Math.floor(endingValueDifference / 100)
      } else {
        return 0
      }
    },
    getLongevityIndicatorValue: state => {
      if (
        typeof state.quickstartResults !== 'undefined' &&
        Object.keys(state.quickstartResults).length > 0 &&
        typeof state.quickstartResults.bestResponse !== 'undefined' &&
        state.quickstartResults.bestResponse !== null &&
        Object.keys(state.quickstartResults.bestResponse).length > 0
      ) {
        let longevityDifference =
          state.quickstartResults.bestResponse.results.longevity -
          state.quickstartResults.worstResponse.results.longevity
        return longevityDifference / 12
      } else {
        return 0
      }
    },
    getContentSnippet: state => key => {
      if (
        typeof state.contentSnippets !== 'undefined' &&
        Object.keys(state.contentSnippets).length > 0 &&
        typeof state.contentSnippets[key] !== 'undefined' &&
        Object.keys(state.contentSnippets).length > 0
      ) {
        return state.contentSnippets[key].content
      } else {
        return ''
      }
    },
    getContentSnippetNoHtml: state => key => {
      if (
        typeof state.contentSnippets !== 'undefined' &&
        Object.keys(state.contentSnippets).length > 0 &&
        typeof state.contentSnippets[key] !== 'undefined' &&
        Object.keys(state.contentSnippets).length > 0
      ) {
        return state.contentSnippets[key].content.replace(/<(?:.|\n)*?>/gm, '')
      } else {
        return ''
      }
    },
    getImageUrl: state => filename => {
      return typeof state.contentImages[filename] !== 'undefined'
        ? state.contentImages[filename].url
        : ''
    },
    getCurrentPage: state => {
      return state.currentPage
    },
    getPlanType: state => {
      return (state.quickstartConfiguration.planType === 'None')
        ? null
        : state.quickstartConfiguration.planType
    },
    getPlanName: state => {
      return state.quickstartConfiguration.planName
    },
    getBrandName: state => {
      return state.quickstartConfiguration.brandName
    },
    getEmailReportModalActive: state => {
      return state.emailReportModalActive
    },
    getGetMoreTeaserEmailModalActive: state => {
      return state.getMoreTeaserModalActive
    }
  }
})

export default store
