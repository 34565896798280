<template>
  <div>
    <get-more></get-more>
  </div>
</template>

<script>
import GetMore from '@/components/GetMoreMini'
import { MonthlyApiService } from '@/services/MonthlyApiService'

const monthlyApiService = new MonthlyApiService()

export default {
  data () {
    return {

    }
  },
  computed: {
  },
  components: {
    GetMore
  },
  created () {
    monthlyApiService.getResults().then(() => {})
  },
  mounted () {
    this.$store.dispatch('setCurrentPage', this.$router.currentRoute.meta.page)
  }
}
</script>

<style scoped>

</style>
