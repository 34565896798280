<template>
  <div class="panel">
    <div class="panel-block">
      <section class="content-section-full">
        <h3 class="is-size-4">Our FREE report shows you how to "GET MORE"</h3>
        <b-field label="Your Email Address">
          <b-input v-model="emailAddressInput" data-vv-name="emailAddressInput" data-vv-scope="step0" v-validate="'required|email'" ></b-input>
        </b-field>
        <span v-show="errors.has('emailAddressInput', 'step0')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('emailAddressInput', 'step0') }}</span>
        <label class="label">Phone Number</label>
        <vue-tel-input v-model="phoneNumberInput" placeholder="Please enter your phone number" :onlyCountries="['us', 'ca']" data-vv-name="phoneNumberInput" v-validate="'required'"></vue-tel-input>
        <span v-show="errors.has('phoneNumberInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('phoneNumberInput') }}</span>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTelInput from 'vue-tel-input'

export default {
  inject: ['$validator'],
  data () {
    return {
      emailAddressInput: null,
      retireeNameInput: null,
      phoneNumberInput: null
    }
  },
  components: {
    VueTelInput
  },
  computed: {
    ...mapGetters([
      'clientEmail',
      'clientName',
      'clientPhoneNumber'
    ])
  },
  watch: {
    emailAddressInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientEmail(newValue)
      }
    },
    retireeNameInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientName(newValue)
      }
    },
    phoneNumberInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientPhoneNumber(newValue)
      }
    }
  },
  methods: {
    ...mapActions({
      updateClientEmail: 'updateClientEmail',
      updateClientName: 'updateClientName',
      updateClientPhoneNumber: 'updateClientPhoneNumber'
    })
  },
  mounted () {
    if (this.clientEmail) {
      this.emailAddressInput = this.clientEmail
    }
  },
  created () {
  }
}
</script>

<style scoped>
</style>
