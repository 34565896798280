<template>
  <div>
    <slot name="header"></slot>
    <component v-bind:is="layout" >
      <transition name="fade" mode="out-in">
          <router-view />
      </transition>
    </component>
    <slot name="footer"></slot>
    <v-tour name="inputTour" :steps="inputTourSteps" :options="inputTourOptions" :callbacks="inputTourCallbacks"></v-tour>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MonthlyApiService } from '@/services/MonthlyApiService'
const defaultLayout = 'default'
const monthlyApiService = new MonthlyApiService()

export default {
  name: 'App',
  data () {
    return {
      inputTourOptions: {
        startTimeout: 5000
      },
      inputTourCallbacks: {
        onStart: this.setTourStarted,
        onStop: this.setTourComplete
      },
      inputTourSteps: [
        {
          target: '#slider-container',
          content: 'Use the slider to set your estimated monthly spending in retirement.'
        },
        {
          target: '#household-type',
          content: 'Set your household, current age and age you plan to retire.',
          params: {
            placement: 'right'
          }
        },
        {
          target: '#investment-risk-control',
          content: 'Select an investment risk level.',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '#savings-panel',
          content: 'Set your retirement savings amounts.',
          params: {
            placement: 'left'
          }
        },
        {
          target: '#income-panel',
          content: 'Set your monthly income in retirement.',
          params: {
            placement: 'left'
          }
        },
        {
          target: '#calculate-button-container',
          content: 'Finally, click the Calculate button to see your retirement strategy',
          params: {
            placement: 'left'
          }
        }
      ]
    }
  },
  props: {
    contextPath: {},
    apiKey: {},
    templateKey: ''
  },
  created: function () {
    this.$store.dispatch('setLeadSource', this.$route.query.src)
    const initialize = async () => {
      var qsParam = this.$route.query.qs
      if (typeof qsParam !== 'undefined') {
        this.$store.dispatch('updateRequestHash', qsParam)
      } else {
        if (this.$cookies.isKey('requestHash')) {
          this.$store.dispatch('updateRequestHash', this.$cookies.get('requestHash'))
        }
      }
      const qsResponse = await monthlyApiService.getQuickstartRequest()
      this.$store.dispatch('updateQuickstartRequest', qsResponse.data.payload)
    }
    initialize()
  },
  computed: {
    ...mapGetters([
      'getEditMode'
    ]),
    layout () {
      return (this.$route.meta.layout || defaultLayout) + '-layout'
    }
  },
  methods: {
    setTourStarted () {
      this.$cookies.set('inputTour', 'started', Infinity)
    },
    setTourComplete () {
      this.$cookies.set('inputTour', 'complete', Infinity)
    }
  },
  mounted () {
    if (this.templateKey !== 'qs3' && this.templateKey !== 'qs3mini') {
      if (!this.$cookies.isKey('inputTour') &&
        this.$cookies.get('inputTour') !== 'complete' &&
        this.getEditMode === null) {
        this.$tours['inputTour'].start()
      }
    }
  }
}
</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  text-align: center;
}

.nav-top {
  background-color: #363636;
  width: 100%;
  color:#f6f7f8;
}

.nav-top h5 {
  color:aqua;
}

.nav-top h4 a {
  color:#f6f7f8;
}

.nav-top h3 a {
  color: #e6ad5c;
}

.is-sidebar-menu {
  /* border-right: 1px; */
  background-color: #363636;
  color: #fff;
  padding-right: 0;
}

.navbar-item .icon {
  vertical-align: middle;
}

.menu-list a.is-active {
  background-color: #065798;
}

.menu-list a {
    color: #e2dede;
}

.panel > .panel-block > .content-section-full {
  width: 100%;
  max-width: 400px;
}

.panel > .panel-heading {
  background: transparent;
}

.panel > .panel-heading:first-child, .panel > .panel-tabs:first-child, .panel > .panel-block:first-child {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.panel > .panel-heading, .panel > .panel-tabs, .panel > .panel-block {
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.field.has-addons input {
  border-top-left-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0px;
}

#wizard .wizard-header {
  padding: 0px 15px;
}

.focus,
.focus .panel {
  border-width: 3px;
  border-radius: 4px;
  border-style: solid;
}
/* TADA animation start */
.focus {
  animation-name: animationFrames, pulse;
  animation-timing-function: linear, linear;
  animation-iteration-count: 1, infinite;
  animation-delay: 0s, 2s;
  animation-duration: 1s, 2s;
  transform-origin: 50% 50%;
  -webkit-animation-duration: 1s, 2s;
  -webkit-animation-delay: 0s, 2s;
  -webkit-animation-iteration-count: 1, infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation-duration: 2s;
  -moz-animation-delay: 0s, 2s;
  -moz-animation-iteration-count: 1, infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation-duration: 1s, 2s;
  -o-animation-delay: 0s, 2s;
  -o-animation-iteration-count: 1, infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation-iteration-count: 1, infinite;
  -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames{
  0% {
    transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-moz-keyframes animationFrames{
  0% {
    -moz-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -moz-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -webkit-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -o-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -ms-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}
/* TADA animation end */

</style>
