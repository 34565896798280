<template>
  <div class="panel">
    <p v-show="!hidePanelHeading" class="panel-heading columnhead">Your Household Retirement Profile</p>
    <div class="panel-block">
      <section class="content-section-full">
        <div :class="{ 'focus': isIncomeEdit }" :style="[isIncomeEdit ? focusStyle : {}]">
          <b-field label="Are you married?" id="household-type">
            <b-field>
              <b-radio-button v-model="isMarriedInput" native-value=true ><span>Yes</span></b-radio-button>
              <b-radio-button v-model="isMarriedInput" native-value=false ><span>No</span></b-radio-button>
            </b-field>
          </b-field>
        </div>
        <div class="columns">
          <div class="column">
            <div id="household-age">
            <b-field label="How old are you?" :layout="layout">
              <b-input v-model="clientAgeInput" data-vv-name="clientAgeInput" v-validate="'numeric|min_value:' + getAgeMin +'|max_value:' + getAgeMax"  ></b-input>
            </b-field>
            <span v-show="errors.has('clientAgeInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('clientAgeInput') }}</span>
            </div>
            <div v-show="isMarried">
              <b-field label="How old is your partner?">
                <b-input v-model="partnerAgeInput" data-vv-name="partnerAgeInput" v-validate="{ rules: { required: spouseRequired, numeric: true, min_value: getAgeMin, max_value: getAgeMax } }" />
              </b-field>
              <span v-show="errors.has('partnerAgeInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('partnerAgeInput') }}</span>
            </div>
            <div id="household-retirementage">
            <b-field label="At what age do you plan to retire?">
              <b-input v-model="clientRetirementAgeInput" data-vv-name="clientRetirementAgeInput" v-validate="'numeric|min_value:' + getRetirementAgeMin +'|max_value:' + getRetirementAgeMax"/>
            </b-field>
            <span v-show="errors.has('clientRetirementAgeInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('clientRetirementAgeInput') }}</span>
            </div>
            <div v-show="getPlanType === null" id="household-annualcontributions">
              <b-field label="How much do you annually contribute to your retirement savings?">
                <cleave class="input" placeholder="Enter your tax deferred annual contributions" v-model="taxDeferredContributionInput" data-vv-name="taxDeferredBalanceContributionInput" v-validate="options.v_number_required" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('taxDeferredContributionInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxDeferredContributionInput') }}</span>
            </div>
            <div id='investment-risk-container' :class="{ 'focus': isRiskEdit }" :style="[isRiskEdit ? focusStyle : {}]">
              <label class="label">What is your investment profile? <b-tooltip type="is-info" label="Investment Profile is the related to the amount of risk you are willing to assume in your investments." position="is-left" multilined><i class="fas fa-info-circle"></i></b-tooltip></label>
              <b-field>
                <b-select id="investment-risk-control" placeholder="Select your investment profile" v-model="investmentProfileInput" data-vv-name="investmentProfileInput" v-validate="'required'">
                  <option
                    v-for="option in allocationStrategyOptions"
                    :value="option.strategyId"
                    :key="option.strategyId">
                    {{ option.riskTolerance }}
                  </option>
                </b-select>
              </b-field>
              <span v-show="errors.has('investmentProfileInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('investmentProfileInput') }}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- / div END -->

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      layout: 'horizontal',
      isMarriedInput: (this.married) ? 'true' : 'false',
      clientAgeInput: this.$store.state.quickstartRequest.retireeAge,
      clientRetirementAgeInput: null,
      partnerAgeInput: null,
      partnerRetirementAgeInput: null,
      investmentProfileInput: null,
      taxDeferredContributionInput: null,
      allocationStrategyOptions: null,
      options: {
        number: {
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 0
        },
        v_number_required: {
          required: true
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  watch: {
    isMarriedInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateIsMarried(newValue)
        this.updateDirtyForm(true)
      }
    },
    clientAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientAge(newValue)
        this.updateDirtyForm(true)
      }
    },
    clientAge: function () {
      this.clientAgeInput = this.clientAge
    },
    clientRetirementAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateClientRetirementAge(newValue)
      }
    },
    clientRetirementAge: function () {
      this.clientRetirementAgeInput = this.clientRetirementAge
    },
    partnerAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePartnerAge(newValue)
      }
    },
    partnerRetirementAgeInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePartnerRetirementAge(newValue)
      }
    },
    investmentProfileInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateInvestmentProfile(newValue)
      }
    },
    investmentProfile: function () {
      this.investmentProfileInput = this.investmentProfile
    },
    taxDeferredContributionInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredContribution(newValue)
      }
    },
    allocationStrategies: function () {
      this.initAllocationStrategyOptions()
    }
  },
  computed: {
    ...mapGetters([
      'married',
      'isMarried',
      'clientAge',
      'clientRetirementAge',
      'partnerAge',
      'partnerRetirementAge',
      'investmentProfile',
      'taxDeferredContribution',
      'allocationStrategies',
      'getPlanType',
      'getEditMode',
      'getAgeMin',
      'getAgeMax',
      'getRetirementAgeMin',
      'getRetirementAgeMax'
    ]),
    spouseRequired: function () {
      if (this.married === 'true') {
        return true
      } else {
        return false
      }
    },
    isRiskEdit: function () {
      return (this.getEditMode === 'risk')
    },
    isIncomeEdit: function () {
      return (this.getEditMode === 'income')
    }
  },
  methods: {
    ...mapActions({
      updateIsMarried: 'updateIsMarried',
      updateClientAge: 'updateClientAge',
      updateClientRetirementAge: 'updateClientRetirementAge',
      updatePartnerAge: 'updatePartnerAge',
      updatePartnerRetirementAge: 'updatePartnerRetirementAge',
      updateTaxDeferredContribution: 'updateTaxDeferredContribution',
      updateInvestmentProfile: 'updateInvestmentProfile',
      updateDirtyForm: 'updateDirtyForm'
    }),
    initAllocationStrategyOptions: function () {
      let options = []
      this.allocationStrategies.forEach(strategy => {
        options.push({ strategyId: strategy.strategyId, riskTolerance: strategy.riskTolerance })
      })
      this.allocationStrategyOptions = options
    }
  },
  mounted () {
    this.isMarriedInput = (this.married) ? 'true' : 'false'
    this.clientAgeInput = this.clientAge
    this.isMarriedInput = (this.married) ? 'true' : 'false'
    this.clientRetirementAgeInput = this.clientRetirementAge
    this.partnerAgeInput = this.partnerAge
    this.partnerRetirementAgeInput = this.partnerRetirementAge
    this.taxDeferredContributionInput = this.taxDeferredContribution
    this.initAllocationStrategyOptions()
    this.investmentProfileInput = this.investmentProfile
  },
  created () {
    this.isMarriedInput = (this.married) ? 'true' : 'false'
    this.clientAgeInput = this.clientAge
    this.isMarriedInput = (this.married) ? 'true' : 'false'
    this.clientRetirementAgeInput = this.clientRetirementAge
    this.partnerAgeInput = this.partnerAge
    this.partnerRetirementAgeInput = this.partnerRetirementAge
    this.taxDeferredContributionInput = this.taxDeferredContribution
    this.initAllocationStrategyOptions()
    this.investmentProfileInput = this.investmentProfile
  }
}
</script>

<style scoped>
#household-type{
  margin-bottom:25px;
  font-size:25px;
}
#household-age{
  margin-bottom:25px;
}
#household-retirementage{
  margin-bottom:25px;
}
#household-annualcontributions{
  margin-bottom:25px;
}
</style>
