<template>
  <div id="longevity-container" class="columns">
    <div class="column">
      <div v-if="getMoreAmount > -1">
        <div id="longevity-chart-container">
          <longevity-line-chart :chart-data="getLongevityChartData" :options="options" :width="300" :height="300"></longevity-line-chart>
        </div>
      </div>
    </div>
    <div id="longevity-content" class="column" v-html="getContentSnippet('longevity-content')">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LongevityLineChart from '@/components/charts/LongevityLineChart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

class LongevityChartItem {
  constructor (label, data, backgroundColor) {
    this.label = label
    this.data = data
    this.backgroundColor = backgroundColor
  }
}

export default {
  data () {
    return {
      longevityChartData: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            id: 'default',
            ticks: {
              callback: function (label, index, labels) {
                if (label) {
                  return '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(label)
                }
              }
            }
          }]
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    }
  },
  components: {
    LongevityLineChart,
    ChartJsPluginDataLabels
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getOptimalResults',
      'getSubOptimalResults',
      'getMoreAmount'
    ]),
    getLongevityChartData () {
      let chartData = {}
      let datasets = []
      let labels = []

      let subOptimalDataset = new LongevityChartItem('Typical Strategy', [], ['rgba(3, 1, 26, 1)'])
      this.getSubOptimalResults.forEach(result => {
        let label = ['Age:' + result.clientEoyAge.y + ' [' + result.year + ']']
        labels.push(label)
        subOptimalDataset.data.push(Math.floor(result.totalBalance / 100))
      })

      let optimalDataset = new LongevityChartItem('Smart Strategy', [], ['rgba(68, 162, 27, 1)'])
      this.getOptimalResults.forEach(result => {
        optimalDataset.data.push(Math.floor(result.totalBalance / 100))
      })

      datasets.push(subOptimalDataset)
      datasets.push(optimalDataset)

      chartData.labels = labels
      chartData.datasets = datasets
      return chartData
    }
  },
  methods: {

  },
  watch: {
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style scoped>
  #longevity-chart-container {
    background-color: #ffffff;
    padding: 15px 10px;
  }
</style>
