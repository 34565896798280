<template>
  <div class="columns">
    <div class="column">
      <div>
        <form ref="form" @submit.prevent="onSubmit">
          <form-wizard id="wizard" ref="wizard" @on-complete="onSubmit" title="" subtitle="" shape="circle" @on-change="tabChange" transition="fade" step-size='sm' :color="stepColor" :errorColor="errorColor">
            <tab-content v-if="getUseEmailStarter" title="Get Started" icon="fas fa-user" :before-change="saveContact">
              <email-starter hide-panel-heading></email-starter>
            </tab-content>
            <tab-content title="Profile" icon="fas fa-home" :before-change="() => stepComplete('Profile')">
              <demographics hide-panel-heading></demographics>
            </tab-content>
            <tab-content title="Spending" icon="fas fa-shopping-cart" :before-change="() => stepComplete('Spending')">
              <spending hide-panel-heading ref="spending" />
            </tab-content>
            <tab-content title="Savings" icon="fas fa-money-bill-wave" :before-change="() => stepComplete('Savings')">
              <savings v-show="!getPlanType" hide-panel-heading></savings>
              <savings-plan v-show="getPlanType" hide-panel-heading></savings-plan>
            </tab-content>
            <tab-content title="Income" icon="fas fa-money-check-alt" :before-change="() => stepComplete('Income')">
              <income hide-panel-heading ></income>
            </tab-content>
          </form-wizard>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapGetters, mapActions } from 'vuex'
import EmailStarter from '@/components/EmailStarter'
import Demographics from '@/components/Demographics'
import Savings from '@/components/Savings'
import SavingsPlan from '@/components/SavingsPlan'
import Income from '@/components/Income'
import Spending from '@/components/SpendingSlider'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import GetMore from '@/components/GetMore'

const monthlyApiService = new MonthlyApiService()

export default {
  inject: ['$validator'],
  provide () {
    return {
      $validator: this.$validator
    }
  },
  props: {
    stepColor: {
      type: String,
      default: null
    },
    errorColor: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showProcessing: false,
      showResults: false,
      profileComplete: false,
      spendingComplete: false,
      savingsComplete: false,
      incomeComplete: false
    }
  },
  components: {
    FormWizard,
    TabContent,
    EmailStarter,
    Demographics,
    Income,
    Savings,
    SavingsPlan,
    Spending,
    GetMore
  },
  computed: {
    ...mapGetters({
      stepper: 'stepper',
      getContentSnippet: 'getContentSnippet',
      getMoreAmount: 'getMoreAmount',
      getPlanType: 'getPlanType',
      getUseEmailStarter: 'getUseEmailStarter'
    })
  },
  methods: {
    ...mapActions({
      updateHideBanner: 'updateHideBanner'
    }),
    saveContact () {
      return new Promise((resolve, reject) => {
        this.$validator.validate('step0.*').then(valid => {
          if (valid) {
            if (this.$store.getters.requestHash === 'new') {
              monthlyApiService.startQuickstart().then((response) => {
                this.$store.dispatch('updateRequestHash', response.data.payload)
              }
              )
            }
          }
          resolve(valid)
        }
        )
        this.stepComplete('Get Started')
      }
      )
    },
    onSubmit () {
      this.showProcessing = true
      monthlyApiService.startQuickstart().then((response) => {
        this.$store.dispatch('updateRequestHash', response.data.payload)
        monthlyApiService.startOptimize().then((response) => {
          this.$store.dispatch('updateQuickstartResults', response.data.payload)
          this.$router.push({ path: 'resultsmini' })
        }
        )
      }
      )
    },
    tabChange: function () {
      let activeTab = this.$refs.wizard.tabs[this.$refs.wizard.activeTabIndex].title
      this.$gtm.trackEvent({
        event: 'quickstart_step_event',
        category: 'Quickstart Steps',
        action: 'start',
        label: activeTab
      })
      if (this.$refs.spending) {
        this.$refs.spending.refreshSlider()
      }
    },
    stepComplete: function (step) {
      this.$gtm.trackEvent({
        event: 'quickstart_step_event',
        category: 'Quickstart Steps',
        action: 'complete',
        label: step
      })
      return true
    }
  },
  created () {
    this.updateHideBanner(true)
  },
  mounted () {
    this.updateHideBanner(true)
    this.$store.dispatch('clearDirtyForm')
  }
}
</script>

<style scoped>
</style>
