<template>
  <section>
    <div class="panel" id="savings-panel">
      <p v-show="!hidePanelHeading" class="panel-heading">Your Retirement Savings</p>
      <div class="panel-block">
        <section class="content-section-full">
          <div class="columns">
            <div class="column">
              <div id="plan-data-card" class="card">
                <div class="card-header">
                  <p class="card-header-title">Your {{getPlanName}}</p>
                </div>
                <div class="card-content">
                  <b-field label="Total retirement plan savings">
                    <cleave class="input" placeholder="Enter your tax deferred savings balance" v-model.number="planTaxDeferredBalanceInput" data-vv-name="planTaxDeferredBalanceInput" v-validate="options.v_number_required" :options="options.number"></cleave>
                  </b-field>
                  <span v-show="errors.has('planTaxDeferredBalanceInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('planTaxDeferredBalanceInput') }}</span>
                  <b-field label="Annual retirement plan contributions">
                    <cleave class="input" placeholder="Enter your tax deferred annual contributions" v-model.number="taxDeferredContributionInput" data-vv-name="taxDeferredBalanceContributionInput" v-validate="options.v_number_required" :options="options.number"></cleave>
                  </b-field>
                  <span v-show="errors.has('taxDeferredContributionInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxDeferredContributionInput') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!showOutsideSavings" class="columns">
            <div class="column"><button type="button" @click="toggleShowOutsideSavings" class="button is-secondary" href="#">Click to include additional outside savings</button></div>
          </div>
          <div v-else class="columns">
            <div class="column" :class="{ 'focus': isOutsideSavingsEdit }" :style="[isOutsideSavingsEdit ? focusStyle : {}]">
              <label class="label">Enter additional outside savings balances below.</label>
              <b-field label="Taxable Balance">
                <cleave class="input" placeholder="Enter your taxable savings balance" v-model.number="taxableBalanceInput" data-vv-name="taxableBalanceInput" v-validate="options.v_number_required" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('taxableBalanceInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxableBalanceInput') }}</span>
              <b-field label="Tax Deferred Balance">
                <cleave class="input" placeholder="Enter your tax deferred savings balance" v-model.number="taxDeferredBalanceInput" data-vv-name="taxDeferredBalanceInput" v-validate="options.v_number_required" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('taxDeferredBalanceInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxDeferredBalanceInput') }}</span>
              <b-field label="Tax Exempt Balance">
                <cleave class="input" placeholder="Enter your tax exempt savings balance" v-model.number="taxExemptBalanceInput" data-vv-name="taxExemptBalanceInput" v-validate="options.v_number_required" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('taxExemptBalanceInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('taxExemptBalanceInput') }}</span>
              <b-field label="Annuity Balance">
                <cleave class="input" placeholder="Enter the balance of all your annuities" v-model.number="annuityBalanceInput" data-vv-name="annuityBalanceInput" v-validate="options.v_number_required" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('annuityBalanceInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('annuityBalanceInput') }}</span>
              <b-field label="Inherited IRA Balance">
                <cleave class="input" placeholder="Enter balance of inherited IRAs" v-model.number="inheritedIraBalanceInput" data-vv-name="inheritedIraBalanceINput" v-validate="options.v_number_required" :options="options.number"></cleave>
              </b-field>
              <span v-show="errors.has('inheritedIraBalanceInput')" class="help is-danger"><font-awesome-icon icon="exclamation-triangle" /> {{ errors.first('inheritedIraBalanceInput') }}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['$validator'],
  props: {
    hidePanelHeading: Boolean
  },
  data () {
    return {
      showOutsideSavings: false,
      planTaxableBalanceInput: null,
      taxableBalanceInput: null,
      taxDeferredBalanceInput: null,
      taxDeferredContributionInput: null,
      planTaxDeferredBalanceInput: null,
      taxExemptBalanceInput: null,
      planTaxExemptBalanceInput: null,
      annuityBalanceInput: null,
      inheritedIraBalanceInput: null,
      options: {
        number: {
          prefix: '$',
          numeral: true,
          numeralPositiveOnly: true,
          noImmediatePrefix: true,
          rawValueTrimPrefix: true,
          numeralIntegerScale: 9,
          numeralDecimalScale: 0
        },
        v_number_required: {
          required: true
        }
      },
      focusStyle: {
        'padding': '10px',
        'border-radius': '4px'
      }
    }
  },
  components: {
  },
  watch: {
    taxableBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxableBalance(newValue)
      }
    },
    planTaxableBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePlanTaxableBalance(newValue)
      }
    },
    taxDeferredBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredBalance(newValue)
      }
    },
    taxDeferredContributionInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxDeferredContribution(newValue)
      }
    },
    taxDeferredContribution: function () {
      this.taxDeferredContributionInput = this.taxDeferredContribution
    },
    planTaxDeferredBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePlanTaxDeferredBalance(newValue)
      }
    },
    planTaxDeferredBalance: function () {
      this.planTaxDeferredBalanceInput = this.planTaxDeferredBalance
    },
    taxExemptBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTaxExemptBalance(newValue)
      }
    },
    planTaxExemptBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePlanTaxExemptBalance(newValue)
      }
    },
    annuityBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateAnnuityBalance(newValue)
      }
    },
    inheritedIraBalanceInput (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateInheritedIraBalance(newValue)
      }
    }
  },
  computed: {
    ...mapGetters([
      'taxableBalance',
      'planTaxableBalance',
      'taxDeferredBalance',
      'taxDeferredContribution',
      'planTaxDeferredBalance',
      'taxExemptBalance',
      'planTaxExemptBalance',
      'annuityBalance',
      'inheritedIraBalance',
      'getPlanName',
      'getEditMode'
    ]),
    isOutsideSavingsEdit: function () {
      return (this.getEditMode === 'outsidesavings')
    }
  },
  methods: {
    ...mapActions({
      updateTaxableBalance: 'updateTaxableBalance',
      updatePlanTaxableBalance: 'updatePlanTaxableBalance',
      updateTaxDeferredBalance: 'updateTaxDeferredBalance',
      updatePlanTaxDeferredBalance: 'updatePlanTaxDeferredBalance',
      updateTaxExemptBalance: 'updateTaxExemptBalance',
      updateTaxDeferredContribution: 'updateTaxDeferredContribution',
      updatePlanTaxExemptBalance: 'updatePlanTaxExemptBalance',
      updateAnnuityBalance: 'updateAnnuityBalance',
      updateInheritedIraBalance: 'updateInheritedIraBalance'
    }),
    toggleShowOutsideSavings: function () {
      this.showOutsideSavings = true
    }
  },
  mounted () {
    this.taxableBalanceInput = this.taxableBalance
    this.planTaxableBalanceInput = this.planTaxableBalance
    this.taxDeferredBalanceInput = this.taxDeferredBalance
    this.planTaxDeferredBalanceInput = this.planTaxDeferredBalance
    this.taxExemptBalanceInput = this.taxExemptBalance
    this.taxDeferredContributionInput = this.taxDeferredContribution
    this.planTaxExemptBalanceInput = this.planTaxExemptBalance
    this.annuityBalanceInput = this.annuityBalance
    this.inheritedIraBalanceInput = this.inheritedIraBalance
    if (this.taxableBalanceInput > 0 ||
    this.taxDeferredBalanceInput > 0 ||
    this.taxExemptBalanceInput > 0 ||
    this.annuityBalanceInput > 0 ||
    this.inheritedIraBalanceInput > 0) {
      this.showOutsideSavings = true
    }
  },
  created () {
    this.taxableBalanceInput = this.taxableBalance
    this.planTaxableBalanceInput = this.planTaxableBalance
    this.taxDeferredBalanceInput = this.taxDeferredBalance
    this.planTaxDeferredBalanceInput = this.planTaxDeferredBalance
    this.taxExemptBalanceInput = this.taxExemptBalance
    this.taxDeferredContributionInput = this.taxDeferredContribution
    this.planTaxExemptBalanceInput = this.planTaxExemptBalance
    this.annuityBalanceInput = this.annuityBalance
    this.inheritedIraBalanceInput = this.inheritedIraBalance
    if (this.taxableBalanceInput > 0 ||
    this.taxDeferredBalanceInput > 0 ||
    this.taxExemptBalanceInput > 0 ||
    this.annuityBalanceInput > 0 ||
    this.inheritedIraBalanceInput > 0 ||
    this.getEditMode === 'outsidesavings') {
      this.showOutsideSavings = true
    }
  }
}
</script>

<style scoped>
</style>
