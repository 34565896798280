<template>
  <div>
    <div v-if="this.sensitivityResults != null">
      <b-table :data="sensitivityTableData" :columns="tableData.columns"></b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MonthlyApiService } from '@/services/MonthlyApiService'

const monthlyApiService = new MonthlyApiService()

export default {
  data () {
    return {
      tableData: {
        data: [],
        columns: [{
          field: 'change',
          label: 'Change',
          numeric: false
        },
        {
          field: 'subOptimal',
          label: 'Sub-Optimal Withdrawal (Strategy 1)',
          numeric: true
        },
        {
          field: 'optimal',
          label: 'Optimal Withdrawal (Strategy 2)',
          numeric: true
        },
        {
          field: 'difference',
          label: 'Difference',
          numeric: true
        }]
      }
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'sensitivityResults'
    ]),
    sensitivityTableData () {
      let dataArray = []
      dataArray.push({
        change: 'Increase Spending',
        subOptimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxInefficientIncreasedSpendingResult / 100)),
        optimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientIncreasedSpendingResult / 100)),
        difference: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientIncreasedSpendingResult / 100) - Math.floor(this.sensitivityResults.taxInefficientIncreasedSpendingResult / 100))
      })
      dataArray.push({
        change: 'Decrease Spending',
        subOptimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxInefficientDecreasedSpendingResult / 100)),
        optimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientDecreasedSpendingResult / 100)),
        difference: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientDecreasedSpendingResult / 100) - Math.floor(this.sensitivityResults.taxInefficientDecreasedSpendingResult / 100))
      })
      dataArray.push({
        change: 'Higher Taxes',
        subOptimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxInefficientIncreasedTaxRateResult / 100)),
        optimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientIncreasedTaxRateResult / 100)),
        difference: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientIncreasedTaxRateResult / 100) - Math.floor(this.sensitivityResults.taxInefficientIncreasedTaxRateResult / 100))
      })
      dataArray.push({
        change: 'Increased Investment Returns',
        subOptimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxInefficientIncreasedMarketReturnsResult / 100)),
        optimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientIncreasedMarketReturnsResult / 100)),
        difference: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientIncreasedMarketReturnsResult / 100) - Math.floor(this.sensitivityResults.taxInefficientIncreasedMarketReturnsResult / 100))
      })
      dataArray.push({
        change: 'Decreased Investment Returns',
        subOptimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxInefficientDecreasedMarketReturnsResult / 100)),
        optimal: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientDecreasedMarketReturnsResult / 100)),
        difference: '$ ' + new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.floor(this.sensitivityResults.taxEfficientDecreasedMarketReturnsResult / 100) - Math.floor(this.sensitivityResults.taxInefficientDecreasedMarketReturnsResult / 100))
      })
      return dataArray
    }
  },
  created () {
    monthlyApiService.getSensitivityResults()
  }
}
</script>

<style scoped>

</style>
