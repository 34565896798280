<template>
  <div class="container">
    <back-to-top visibleoffset="500">
      <button type="button" class="btn btn-info btn-back-to-top"><font-awesome-icon size="3x" :icon="[ 'far', 'arrow-alt-circle-up']"/></button>
    </back-to-top>
    <floating-button top="400px" visibleoffset="500">
      <button type="button" class="button is-primary btn-email-report" @click="setEmailReportModalActive(true)">Get Your Free Report</button>
    </floating-button>
    <div class="columns">
      <div class="column">
        <get-more></get-more>
      </div>
    </div>
    <!-- <div class="is-divider"></div> -->
    <div id="indicators-container" class="columns has-text-centered" style="display: none;">
      <div class="column">
        <total-value-indicator></total-value-indicator>
      </div>
      <div class="column">
        <longevity-indicator></longevity-indicator>
      </div>
      <div class="column">
        <ending-value-indicator></ending-value-indicator>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <get-more-zone v-show="getMoreAmount > -1"></get-more-zone>
        </transition>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <longevity  v-if="getLongevityIndicatorValue > 3 || getEndingValueIndicatorAmount >= 10000"></longevity>
          <s-s-get-more v-else></s-s-get-more>
        </transition>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <transition name="slide-fade">
          <sensitivity-impacts v-show="getMoreAmount > -1"></sensitivity-impacts>
        </transition>
      </div>
    </div>
    <div v-show="getPlanType === '401k' || getPlanType === 'Roth'">
      <div class="columns">
        <div class="column">
          <transition name="slide-fade">
            <spending-result v-if="getMoreAmount > -1"></spending-result>
          </transition>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <transition name="slide-fade">
            <!-- use v-if so component is rendered after results are returned -->
            <savings-result v-if="getMoreAmount > -1 && getOptimalSSIAmount > 0"></savings-result>
          </transition>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <transition name="slide-fade">
            <other-income-result v-show="getMoreAmount > -1"></other-income-result>
          </transition>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <transition name="slide-fade">
            <investment-risk-result v-show="getMoreAmount > -1"></investment-risk-result>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GetMore from '@/components/GetMore'
import Longevity from '@/components/Longevity'
import GetMoreZone from '@/components/GetMoreZone'
import SSGetMore from '@/components/SSGetMore'
import TotalValueIndicator from '@/components/TotalValueIndicator'
import LongevityIndicator from '@/components/LongevityIndicator'
import EndingValueIndicator from '@/components/EndingValueIndicator'
import SensitivityImpacts from '@/components/SensitivityImpacts'
import SpendingResult from '@/components/SpendingResult'
import SavingsResult from '@/components/SavingsResult'
import OtherIncomeResult from '@/components/OtherIncomeResult'
import InvestmentRiskResult from '@/components/InvestmentRiskResult'
import { MonthlyApiService } from '@/services/MonthlyApiService'
import BackToTop from 'vue-backtotop'
import FloatingButton from '@/components/FloatingButton'

const monthlyApiService = new MonthlyApiService()

export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters([
      'getLongevityIndicatorValue',
      'getMoreAmount',
      'getOptimalSSIAmount',
      'getEndingValueIndicatorAmount',
      'getPlanType'
    ])
  },
  components: {
    GetMore,
    SSGetMore,
    Longevity,
    GetMoreZone,
    TotalValueIndicator,
    LongevityIndicator,
    EndingValueIndicator,
    SensitivityImpacts,
    SpendingResult,
    SavingsResult,
    OtherIncomeResult,
    InvestmentRiskResult,
    BackToTop,
    FloatingButton
  },
  methods: {
    ...mapActions({
      setEmailReportModalActive: 'setEmailReportModalActive'
    })
  },
  created () {
    monthlyApiService.getResults().then(() => {})
  },
  mounted () {
    this.$store.dispatch('setCurrentPage', this.$router.currentRoute.meta.page)
    this.$store.dispatch('clearEditMode')
  }
}
</script>

<style scoped>
  #indicators-container {
    display: none;
  }
  #resultsbg{
    background:#fff;
    width:100%;
  }
  #widget-container .btn-back-to-top {
    background-color: #209cee;
    border-radius: 30px;
    color: #ffffff;
    height: 60px;
    text-align: center;
    width: 60px;
    padding: 0px;
    border-style: solid;
  }

   #widget-container .btn-email-report {
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 1.5rem;
    text-align: center;
    white-space: nowrap;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
