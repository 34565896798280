<template>
  <div id="banner-container">
    <div class="bannertext">
        <h1 class="bigcta"  v-html="getContentSnippet('banner-cta')"></h1>
        <hr>
        <div v-html="getContentSnippet('banner-content')">
        </div>
    </div>
    <div class="bannerimage">
      <div>
        <img :src='getImageUrl("animatedhead.gif")'>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'getContentSnippet',
      'getImageUrl'
    ])
  }
}
</script>

<style scoped>
#banner-container {
  margin-top: 6px;
  display:grid;
  grid-gap:30px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.bigcta{
  font-size:3em;
  line-height:1em;
  font-weight:800;
  padding-bottom:20px;
  color:#2a97f7;
}
.bannertext{
  margin: 100px 40px 40px 0;
  line-height: 1.25;
  font-weight: 300;
  font-size: 1.4em;
  grid-column-start: 1;
  grid-column-end:4;
}
.bannerimage{
  grid-column-start: 4;
  grid-column-end: 6;
  margin-top:20px;
}
hr{
  margin:.5rem 0 1.25rem 0;
}

@media only screen and (max-width: 1023px) {
    .bannerimage{
      display:none;
    }
    .bannertext{
      grid-column-start: 1;
      grid-column-end: 6;
      margin: 40px 40px 40px 0;
    }
  }
</style>
