<template>
  <div id="investment-risk-result-container">
    <span class="is-size-5">INVESTMENT PROFILE AND RISK</span>
    <div class="columns">
      <div class="column">
        <div class="result-chart-container">
          <div class="columns">
            <div class="column is-half">
              <div v-show="riskTolerance == 'Aggressive'">
                <div v-html="getContentSnippet('risk-tolerance-aggressive')">
                </div>
              </div>
              <div v-show="riskTolerance == 'ModerateAggressive'">
                <div v-html="getContentSnippet('risk-tolerance-moderate-aggressive')">
                </div>
              </div>
              <div v-show="riskTolerance == 'Moderate'">
                <div v-html="getContentSnippet('risk-tolerance-moderate')">
                </div>
              </div>
              <div v-show="riskTolerance == 'ModerateConservative'">
                <div v-html="getContentSnippet('risk-tolerance-moderate-conservative')">
                </div>
              </div>
              <div v-show="riskTolerance == 'Conservative'">
                <div v-html="getContentSnippet('risk-tolerance-conservative')">
                </div>
              </div>
              <div v-show="riskTolerance == 'LowRisk'">
                <div v-html="getContentSnippet('risk-tolerance-low-risk')">
                </div>
              </div>
            </div>
            <div class="column is-half">
              <allocation-pie-chart :chartData="chartData" :options="chartOptions"></allocation-pie-chart>
            </div>
          </div>

        </div>
      </div>
      <div class="column has-text-centered">
        <div id="investment-result-content" v-html="getContentSnippet('investment-result-content')">
        </div>
        <p class="has-text-centered">
          <button class="button is-secondary" @click="setRiskEditMode">Adjust Your Risk</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Chart from 'chart.js'
import AllocationPieChart from '@/components/charts/AllocationPieChart.js'
export default {
  data () {
    return {
      chartOptions: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: function (chart) {
              var helpers = Chart.helpers
              var data = chart.data
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function (label, i) {
                  var meta = chart.getDatasetMeta(0)
                  var ds = data.datasets[0]
                  var arc = meta.data[i]
                  var custom = (arc && arc.custom) || {}
                  var arcOpts = chart.options.elements.arc
                  var fill = custom.backgroundColor ? custom.backgroundColor : helpers.getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor)
                  var stroke = custom.borderColor ? custom.borderColor : helpers.getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor)
                  var bw = custom.borderWidth ? custom.borderWidth : helpers.getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth)
                  return {
                  // And finally :
                    text: ds.data[i] + '% ' + label,
                    fillStyle: fill,
                    strokeStyle: stroke,
                    lineWidth: bw,
                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                    index: i
                  }
                })
              }
              return []
            }
          }
        },
        plugins: {
          datalabels: {
            color: '#ffffff',
            font: {
              size: '16',
              weight: 'bold'
            },
            formatter: function (value, ctx) {
              return ctx.chart.data.labels[ctx.dataIndex]
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getContentSnippet: 'getContentSnippet',
      riskTolerance: 'getRiskTolerance',
      getCurrentInvestmentProfileAllocationMap: 'getCurrentInvestmentProfileAllocationMap'
    }),
    chartData () {
      let chartData = {
        datasets: []
      }
      let labels = []
      let chartDataset = {
        backgroundColor: [],
        data: []
      }
      let map = this.getCurrentInvestmentProfileAllocationMap

      for (var key in map) {
        switch (key) {
          case 'Stock':
            labels.push('Equities')
            chartDataset.backgroundColor.push('#329fca')
            break
          case 'Bond':
            labels.push('Fixed Income')
            chartDataset.backgroundColor.push('#f8a440')
            break
          case 'RealEstate':
            labels.push('REITs')
            chartDataset.backgroundColor.push('#532f4e')
            break
          case 'Cash':
            labels.push(key)
            chartDataset.backgroundColor.push('#84b119')
            break
          case 'Currency':
            labels.push(key)
            chartDataset.backgroundColor.push('#f9be3e')
            break
          case 'Commodities':
            labels.push(key)
            chartDataset.backgroundColor.push('#086b79')
            break
          case 'Alt':
            labels.push(key)
            chartDataset.backgroundColor.push('#c8a444')
            break
          default:
            labels.push(key)
            break
        }
        chartDataset.data.push(map[key])
      }

      chartData.labels = labels
      chartData.datasets.push(chartDataset)
      return chartData
    }
  },
  components: {
    AllocationPieChart
  },
  methods: {
    ...mapActions({
      setEditMode: 'setEditMode'
    }),
    setRiskEditMode: function () {
      this.setEditMode('risk')
      this.$router.push({ path: 'home' })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
  .columns {
    margin-top: 0px;
  }
  #investment-result-content {
    margin-bottom: 15px;
  }
  .result-chart-container {
    padding: 15px;
  }
</style>
