<template>
  <div class="container" id="get-more-container">
      <div class="column" id="freereportbutton">
        <div class="container has-text-centered has-text-weigh-semibold" id="get-more">
          <div id="get-more-amount" v-if="getMoreAmount > -1"  v-bind:class="getAmountClass">
            <ICountUp :endVal="getMoreAmount" :delay="icountupDelay" :options="getCountUpOptions"/>
            <!-- <vue-numeric v-bind:read-only=true currency="$" v-bind:precision=0 v-bind:value="getMoreAmount"></vue-numeric> -->
            <div id="get-more-label">Your <span v-html="getBrandName"></span> Amount</div>
          </div>
          <div id="get-more-spinner" v-else>
            <circle-8 size="120px"></circle-8>
          </div>
        </div>
      </div>
      <!-- <div class="column"> -->
        <!-- remove v-if to start showing Learn More button -->
        <!-- <router-link v-if="1==2" to="/promotion" class="navbar-item button is-link">Learn More<font-awesome-icon size="2x" icon="angle-right"  style="margin-left: 20px;"/></router-link>
      </div> -->
    <div class="getmoretext" id="get-more-content" v-html="getContentSnippet('get-more')">
    </div>
            <div class="has-text-centered" id="personalizedreport" style="padding-top: 25px;">
              <transition name="flip" mode="in-out">
                <a :key="flip" id="email-report-button" class="button is-primary" v-show="getMoreAmount > -1"
                  @click="setEmailReportModalActive(true)">
                  Get Your Free Personalized Report
                </a>
              </transition>
            </div>
            <div class="column" id="recalculate">
       <router-link to="/home" class="navbar-item button is-link"><i class="fas fa-backward pushleft" style="margin-right:20px;"></i>Recalculate</router-link>
      </div>
  </div>
</template>

<script>
import VueNumeric from 'vue-numeric'
import { mapGetters, mapActions } from 'vuex'
import { Circle8 } from 'vue-loading-spinner'
import ICountUp from 'vue-countup-v2'

export default {
  data () {
    return {
      flip: false,
      icountupDelay: 0,
      icountupOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '$',
        suffix: '',
        duration: 2
      }
    }
  },
  components: {
    VueNumeric,
    Circle8,
    ICountUp
  },
  methods: {
    ...mapActions({
      setEmailReportModalActive: 'setEmailReportModalActive'
    }),
    flipButton () {
      this.flip = !this.flip
    }
  },
  computed: {
    ...mapGetters([
      'getMoreAmount',
      'getContentSnippet',
      'getContentSnippetNoHtml',
      'getBrandName',
      'getEmailReportModalActive'
    ]),
    getAmountClass: function () {
      if (this.getMoreAmount < 1000000) {
        return 'get-more-under-1m'
      } else {
        return 'get-more-over-1m'
      }
    },
    getCountUpOptions: function () {
      let opts = this.icountupOptions
      let startVal = Math.ceil((Math.floor(this.getMoreAmount * 0.8)) / 100) * 100
      opts.startVal = (startVal < 50000) ? 0 : startVal
      return opts
    }
  },
  timers: {
    flipButton: {
      time: 15000,
      autostart: true,
      repeat: true
    }
  }
}
</script>

<style scoped>
  #get-more-container .navbar-item {
    font-size: 1.5rem;
  }
  #get-more-container {
    /* padding: 30px 0 0 20px; */
    display:grid;
    grid-template-columns: 400px 341px 1fr;
    grid-template-rows: 1fr 150px;
    grid-template-areas: "tx tx im"
    "rc pr im";
    width:100%;
  }
  /* #get-more {
    background-position: center;
    background-image: url('../assets/getmorebg1.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 409px;
    width: 300px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border: 0px solid #ffffff;
  } */
  #freereportbutton{
    grid-area: im;
  }
  .getmoretext{
    grid-area: tx;
  }
  #personalizedreport{
    grid-area:pr;
  }
  #recalculate{
    grid-area: rc;
    padding-top:25px;
  }
  .pushleft{
    -webkit-animation: pushleft;
    animation: pushleft;
  }

  #email-report-button {
    width: 400px;
    font-size: 1.50rem;
  }
  .get-more-under-1m {
    font-size: 50px;
  }
  .get-more-over-1m {
    font-size: 45px;
  }
  .get-more-over-1m > span {
    padding-bottom: 25px;
  }
  #get-more-amount {
    padding-top: 75px;
  }
  #get-more-label {
    font-size: 18px;
    padding: 0px 25px;
  }
  /* #get-more-spinner {
    padding-top: 60px;
    padding-left: 400px;
  } */
  #get-more-content {
    padding: 45px 0px;
    font-size:1.5em;
  }
  .flip-enter-active {
    transition: all .4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  .flip-leave-active {
    transition: all .6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .flip-enter, .flip-leave-to {
    transform: scaleY(0) translateZ(0);
    opacity: 0;
  }
</style>
